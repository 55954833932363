(function ($) {

    var ucword = function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    $(document).ready(function () {

        var uppercase_fields = [
            '.woocommerce-billing-fields input[name="billing_first_name"]',
            '.woocommerce-billing-fields input[name="billing_last_name"]',
            '.woocommerce-EditAccountForm input[name="account_first_name"]',
            '.woocommerce-EditAccountForm input[name="account_last_name"]',
        ];

        $(uppercase_fields.join(', ')).blur(function () {
            this.value = ucword(this.value);
        });
    });


    function proofguide(proof) {
        var button = $('<div id="bcorr-guide-toggle">');
        var guide = $('<div id="bcorr-guide" class="hide">');
        var img = $('<img>');
        img.attr('src', proof);
        img.appendTo(guide);

        guide.appendTo('body');
        button.appendTo('body');

        $("#bcorr-guide-toggle").on('click', function (event) {
            $("#bcorr-guide").toggleClass("hide");
        });
        $("#bcorr-guide img").on('click', function (event) {
            $("#bcorr-guide").toggleClass("light");
        });

        $(window).resize(function () {
            var screenImage = $("#bcorr-guide img");
            // Create new offscreen image to test
            var theImage = new Image();
            theImage.src = screenImage.attr("src");

            console.log('fuck you');

            // Get accurate measurements from that.
            var imageWidth = theImage.width;
            if (imageWidth > $(window).width()) {
                $("#bcorr-guide img").css("-webkit-filter", "invert(1)");
            } else {
                $("#bcorr-guide img").css("-webkit-filter", "none");
            }
        });
    }

    // proofguide('https://d3sw26zf198lpl.cloudfront.net/2018/11/27/5bfd67591f5a5.jpg');

    $('#xa_myModal .xa-modal-content .xa-container .xa-container').prepend('<h3>Please verify your shipping address</h3>');
    $('#xa_myModal .xa-modal-content #right_title center bold').text('Suggested Address');

    $(document).ready(function () {

        if ($('body').hasClass('postid-64918') || $('body').hasClass('postid-114536') || $('body').hasClass('postid-151177') || $('body').hasClass('postid-258929')) {

            $('.single_add_to_cart_button').attr('disabled', 'disabled');

            var designs_customized = 0;

            $('body').on('click', '[design-id]', function () {
                designs_customized++;

                if (designs_customized >= 2) {
                    $('.single_add_to_cart_button').removeAttr('disabled');
                }
            });

            // $('body').on('click', '#pp_customize_design_btn_2', function () {
            //     designs_customized++;

            //     if (designs_customized >= 2) {
            //         $('.single_add_to_cart_button').removeAttr('disabled');
            //     }
            // });

            $('form.cart').submit(function () {
                if (designs_customized >= 2) {
                    // Allow submission
                } else {
                    alert('Please customize your designs first!');
                    return false;
                }
            });
        }
    });

    // if (getCookie('hubspotutk') != null) {
    //     var hubspotUtk = getCookie('hubspotutk');
    //     var bcoGetUrl = 'https://thingproxy.freeboard.io/fetch/https://api.hubapi.com/contacts/v1/contact/utk/' + hubspotUtk + '/profile?hapikey=c11a393f-8ded-40b0-afe9-4c9f0d2ecc10';
    //
    //     console.log('bcoGetUrl', bcoGetUrl);
    //
    //     $.ajax({
    //         url: bcoGetUrl,
    //         type: 'GET',
    //         success: function (res) {
    //             var json = JSON.parse(res);
    //             console.log('res', res);
    //             console.log('json', json);
    //         }
    //     });
    //
    //     console.log('hubspotUtk', hubspotUtk);
    // }

    // sticky nav
    function bco_stickyNav(selector) {
        var newnav;
        var addContainer = true;
        var append = null;
        if (selector == null) {
            newnav = bco.navigation;
        } else {
            newnav = $(selector);
            addContainer = false;
        }

        var nav = $('<div id="bco-nav-wrapper"></div>');
        var wrap = $('<div id="bco-nav"></div>');
        wrap.appendTo(nav);

        append = wrap;

        if (addContainer) {
            var container = $('<div class="container"></div>');
            container.appendTo(wrap);

            append = container;
        }

        nav.insertAfter(newnav);
        newnav.appendTo(append);

        function resizeNavWrapper() {
            if ($('body.bco-nav-follow').length > 0) {
                var height = $('#bco-nav').height();
                $('#bco-nav-wrapper').css('height', height + 'px');
            } else {
                $('#bco-nav-wrapper').css('height', 'auto');
            }
        }

        $(window).resize(function (event) {
            resizeNavWrapper();
        });

        $(window).scroll(function (event) {
            var navTop = $('#bco-nav-wrapper').offset().top;
            if ($(window).scrollTop() >= navTop) {
                $("body").addClass('bco-nav-follow');
            } else {
                $("body").removeClass('bco-nav-follow');
            }
            resizeNavWrapper();
        });

        $(window).trigger('resize');
    }

    bco_stickyNav('#masthead');

})(jQuery);

(function ($, window, document, undefined) {

    $('.composite_data')

        .on('wc-composite-initializing', function (event, composite) {
            // Add your code here.
            composite.actions.add_action('component_options_state_changed', function () {
                setTimeout(function () {
                    $('#product-114536 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-400').click();
                    $('#product-114536 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-glossy').click();
                    $('#product-114536 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-square').click();

                    $('#product-151177 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-100').click();
                    $('#product-151177 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-glossy').click();
                    $('#product-151177 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-square').click();

                    $('#product-220435 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-100').click();
                    $('#product-220435 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-glossy').click();
                    $('#product-220435 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-square').click();

                    $('#product-208625 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-400').click();
                    $('#product-208625 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-glossy').click();
                    $('#product-208625 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-square').click();

                    $('#product-258929 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-100').click();
                    $('#product-258929 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-matte').click();
                    $('#product-258929 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-square').click();

                }, 500);
            });
            // Add your code here.
            composite.actions.add_action('show_step', function () {
                setTimeout(function () {
                    $('#product-258929 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-100').click();
                    $('#product-258929 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-matte').click();
                    $('#product-258929 .woo-variation-items-wrapper .button-variable-wrapper .button-variable-item-square').click();

                }, 500);
            });
        });
    
    console.log('new code');
    

})(jQuery, window, document);

window.__lo_site_id = 153597;

(function () {
    var wa = document.createElement('script');
    wa.type = 'text/javascript';
    wa.async = true;
    wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wa, s);
})();
